/* App.css */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  padding-top: 80px;
}


footer {
  margin-top: auto; /* Mueve el footer al final del contenedor */
}