/* General navbar styles */
.nav-container {
    /* max-width: 1200px; */
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
  }
  
  nav {
    background-color: #23325A;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .logo {
    max-width: 250px;
    height: auto;
  }
  
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .menu li {
    margin: 0 15px;
  }
  
  .menu li a {
    color: white;
    text-decoration: none;
    font-size: 17px;
  }

  .lang-button {
    font-size: 16px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .lang-switcher:hover {
    color: #b0b0b0; /* Cambia a un tono más gris al hacer hover */
  }
  
  
  .language-switcher {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* Hamburger button for small screens */
  .hamburger {
    display: none;
    background-color: transparent;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  
  
  /* Responsive menu */
  @media (max-width: 1000px) {
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      right: 0;
      background-color: #23325A;
      width: 100%;
      text-align: center;
    }
  
    .menu.open {
      display: flex;
    }
  
    .hamburger {
      display: block;
    }
  
    .language-switcher {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    }
    .logo-container {
      padding-right: 20px; /* Ajusta este valor según sea necesario */
    }
    .nav {
      padding: 0px;
    }
  
  }
  

  
/* Media queries para pantallas grandes */
@media (min-width: 2000px) {
  nav {
      height: 60px; /* Ajustar la altura del navbar */
      padding: 0 30px; /* Ajustar el padding del navbar */
  }

  .logo {
      max-width: 300px; /* Ajustar el tamaño del logo */
  }

  .menu li a {
      font-size: 19px; /* Ajustar el tamaño de la fuente de los enlaces */
  }

  .hamburger {
      font-size: 35px; /* Ajustar el tamaño de la fuente del botón de hamburguesa */
  }
}

@media (min-width: 3000px) {
  nav {
      height: 90px; /* Ajustar la altura del navbar */
      padding: 0 40px; /* Ajustar el padding del navbar */
  }

  .logo-container .logo {
      max-width: 350px; /* Ajustar el tamaño del logo */
  }

  .menu li a {
      font-size: 27px; /* Ajustar el tamaño de la fuente de los enlaces */
  }

  .lang-button {
    font-size: 25px;
  }

  .hamburger {
      font-size: 40px; /* Ajustar el tamaño de la fuente del botón de hamburguesa */
  }
}

@media (min-width: 4000px) {
  nav {
      height: 140px; /* Ajustar la altura del navbar */
      padding: 0 50px; /* Ajustar el padding del navbar */
  }

  .logo {
      max-width: 400px; /* Ajustar el tamaño del logo */
  }

  .menu li a {
      font-size: 28px; /* Ajustar el tamaño de la fuente de los enlaces */
  }

  .hamburger {
      font-size: 45px; /* Ajustar el tamaño de la fuente del botón de hamburguesa */
  }
}