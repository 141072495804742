.contacto-container {
    width: 90%;
    display: flex;
    flex-direction: column; /* Cambiado a columna para centrar todo */
    align-items: center; /* Centra horizontalmente el contenido */ /* Agregar más padding horizontal (izquierda y derecha) */
    text-align: center; /* Alinea el texto al centro */
    margin: 0 auto;
    overflow: hidden;
}

.contacto-title {
    font-size: 2.3rem;
    margin: 0 auto;
    text-align: left; /* Alinear el título a la izquierda */
    width: 100%; 
    color: #23325A;
    font-weight: 900; 
    box-sizing: border-box;
}

.contacto-text {
    font-size: 1.2rem;
    line-height: 0.5;
    color: #777; /* Color del texto */
    border-radius: 10px; /* Bordes redondeados */ /* Espacio interno */
    text-align: left; /* Alinear el texto de la lista a la izquierda */
    width: 100%;
    margin: 15px;
}

.contacto-text .icon {
    color: #25A882; /* Color verde para los iconos */
    margin-right: 10px; /* Espacio entre el icono y el texto */
}

.contacto-text strong {
    color: #25A882; /* Color verde para las palabras "Email" y "WhatsApp" */
    font-weight: 800;
}

.contacto-text strong + strong {
    color: #23325A; /* Color azul para el email y el teléfono */
    font-weight: 800;
}

.contact-link {
    text-decoration: none; /* Quitar la línea subrayada */
    color: inherit; /* Mantener el color del texto */
  }
  
  .contact-link:hover {
    text-decoration: none ; /* Opcional: subrayar al pasar el cursor */
  }

/* Media queries para diferentes resoluciones */
@media (max-width: 1200px) {
    .contacto-container {
        /* Ajustar padding horizontal */
        margin: 0 30px; /* Ajustar margen horizontal */

    }

    .contacto-text {
        padding: 10px;
        line-height: 1.2;
    }
}

@media (max-width: 768px) {
    .contacto-container {
         /* Ajustar padding horizontal */
        margin: 0 auto; /* Ajustar margen horizontal */
    }
}

@media (max-width: 480px) {
    .contacto-container {
        /* Ajustar padding horizontal */
        margin: 0 auto; /* Ajustar margen horizontal */
    }

    .contacto-title {
        margin-top: 10px; /* Reducir margen superior */
        margin-bottom: 2px; /* Reducir margen inferior */
        
    }

    .contacto-text {
        margin-top: 10px; /* Reducir margen superior */
        margin-bottom: 10px; /* Reducir margen inferior */
        line-height: 1.4;
    }
}


/* Media queries para pantallas grandes */
@media (min-width: 2000px) {
    .contacto-title {
        font-size: 3.5rem; /* Ajustar el tamaño de la fuente */
    }

    .contacto-text {
        font-size: 1.5rem; /* Ajustar el tamaño de la fuente */
    }
}

@media (min-width: 3000px) {
    .contacto-title {
        font-size: 4.5rem; /* Ajustar el tamaño de la fuente */
    }

    .contacto-text {
        font-size: 2rem; /* Ajustar el tamaño de la fuente */
    }
}

@media (min-width: 4000px) {
    .contacto-title {
        font-size: 5.5rem; /* Ajustar el tamaño de la fuente */
    }

    .contacto-text {
        font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
    }
}