/* Home.css */

/* Para que el carrusel esté arriba y ocupe todo el ancho */
.container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: -50px;
  
}

.carousel-top {
  width: 100%;
  margin-bottom: 20px; 
  position: relative; /* Si quieres que se comporte como un banner */
  z-index: 1;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.carousel .slide img {
  width: 100%;
  height: 325px;
  object-fit: cover; 
  object-position: center center; 
}


.carousel-top img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Esto garantiza que la imagen mantenga su proporción */
  object-position: center; /* Asegura que la imagen esté centrada completamente */
}



.welcome-section {
  display: flex;
  flex-direction: row; /* Cambia a columnas para que se apilen */
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  text-align: center; /* Asegura que todo el texto esté centrado */
  margin-bottom: 10px;
}

.welcome-text {
  margin-bottom: 20px;
  font-size: 30px; /* Espacio entre el texto y el logo */
}

.welcome-logo .logo {
  max-width: 200px;
  height: auto;
}

.welcome-1 {
  font-size: 2.2rem;
  margin: 10px 0;
  animation: colorChange 6s infinite;
  font-weight: 900;
}

.welcome-2 {
  font-size: 2.2rem;
  margin: 10px 0;
  animation: colorChange2 6s infinite;
  font-weight: 900;
}

/* Tarjetas centradas con flexbox */
.cards-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.card {
  background-color: rgba(35, 50, 90, 0.164); /* Fondo monocromático con opacidad */
  color: white; /* Texto en blanco */
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 60%; 
  box-sizing: border-box;
  display: flex;
  align-items: center;
  opacity: 0.9; /* Opacidad general de la card */
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye el contenido de manera uniforme */
  padding: 20px;
  text-align: left;
}

.card:hover {
  opacity: 1; /* Opacidad al 100% cuando se hace hover */
}

.card img {
  max-width: 50%;
  height: auto;
}

.card-content {
  padding: 20px;
}

/* Alternar posición de las imágenes en las tarjetas */
.card:nth-child(odd) {
  background-color: #23325A; 
  flex-direction: row;
  color: white;
}
.card:nth-child(odd) img {
  border-radius: 0 8px 8px 0; /* Borde para las tarjetas con fondo #23325A */
}

.card:nth-child(odd) h2,
.card:nth-child(odd) p {
  color: white;
}

.card:nth-child(even) {
  background-color: #f0f0f0; /* Gris */
  color: #23325a;
  flex-direction: row-reverse;
}

.card:nth-child(even) img {
  border-radius: 8px 0 0 8px; /* Borde para las tarjetas con fondo blanco */
}


.card:nth-child(even) h2,
.card:nth-child(even) p {
  color: #23325a;
}

.card h2 {
  font-size: 1.6em;
  margin: 10px 0;
}

.card p {
  font-size: 1em;
  color: white;
}

@keyframes colorChange {
  0% {
    color: #25A882; /* Rosa para el primer h2 */
  }
  33% {
    color: #f44cb1; /* El primer h2 pasa a gris */
  }
  66% {
    color: #25A882; /* Verde para el segundo h2 */
  }
  100% {
    color: #f44cb1; /* Ambos h2 terminan en rosa y verde */
  }
}

/* Animación para el segundo h2 */
@keyframes colorChange2 {
  0% {
    color: #f44cb1;; /* Empieza en gris */
  }
  33% {
    color: #25A882; /* Cambia a verde */
  }
  66% {
    color: #f44cb1; /* Cambia a rosa */
  }
  100% {
    color: #25A882; /* Ambos h2 terminan en rosa y verde */
  }
}

/* Media queries */
@media only screen and (max-width: 1024px) {
  .welcome-section {
    flex-direction: column; /* Mantener la disposición en columnas */
    padding: 30px;
  }

  .welcome-text {
    font-size: 1.5em;
    
  }
  .card:nth-child(odd),
  .card:nth-child(even) {
    flex-direction: column;
    width: 80%;
  }

  .card h2 {
    font-size: 1.4em;
  }

  .card p {
    font-size: 1.2em;
  }

  .card img {
    margin-top: 10px;
    max-width: 70%;
    height: auto;
    
  }

}

@media only screen and (max-width: 1000px) {

  .welcome-text {
    font-size: 1.4em;
  }
  .card:nth-child(odd) img,
  .card:nth-child(even) img {
    border-radius: 8px 8px 8px 8px; /* Aplica el mismo borde a todas las imágenes */
  }


  .card h2 {
    font-size: 1.3em;
  }

  .card p {
    font-size: 1.1em;
  } 
}

@media only screen and (max-width: 600px) {
  
  .welcome-1 , .welcome-2 {
    font-size: 1.6rem;
    margin: 0px;
  }
  
  .card:nth-child(odd),
  .card:nth-child(even) {
    flex-direction: column;
    width: 100%;
    padding: 0%;
  }

  .carousel .slide img {
    width: 100%;
    height: 250px;
    object-fit: cover; 
    object-position: center center; /* Cambia de 'top' a 'center' para centrar mejor la imagen */
  }

  .welcome-section {
    flex-direction: column; /* Cambia a columnas para apilar contenido */
    padding: 20px;
  }

  .welcome-text {
    font-size: 1.2em; /* Reducir el tamaño del texto */
  }

  .welcome-logo .logo {
    max-width: 50%; /* Asegura que el logo ocupe todo el ancho disponible */
    height: auto;
  }

  .card h2 {
    font-size: 1.2em;
  }

  .card p {
    font-size: 1em;
  }
}


/* Media queries para pantallas grandes */
@media (min-width: 2000px) {

  .carousel .slide img {
    height: 370px;
  }
  .welcome-1, .welcome-2 {
    font-size: 2.7rem; /* Ajustar el tamaño de la fuente */
  }

  .welcome-text {
    font-size: 2rem; /* Ajustar el tamaño de la fuente */
  }

  .card h2 {
    font-size: 2rem; /* Ajustar el tamaño de la fuente */
  }

  .card p {
    font-size: 1.5rem; /* Ajustar el tamaño de la fuente */
  }
}

@media (min-width: 2500px) {
  .welcome-1, .welcome-2 {
    font-size: 3.3rem; /* Ajustar el tamaño de la fuente */
  }

}


@media (min-width: 3000px) {
  .carousel .slide img {
    height: 400px;
  }
  .welcome-1, .welcome-2 {
    font-size: 4.5rem; /* Ajustar el tamaño de la fuente */
  }

  .welcome-text {
    font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
  }

  .card h2 {
    font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
  }

  .card p {
    font-size: 2rem; /* Ajustar el tamaño de la fuente */
  }
}

@media (min-width: 4000px) {
  .welcome-1, .welcome-2 {
    font-size: 5.5rem; /* Ajustar el tamaño de la fuente */
  }

  .welcome-text {
    font-size: 3rem; /* Ajustar el tamaño de la fuente */
  }

  .card h2 {
    font-size: 3rem; /* Ajustar el tamaño de la fuente */
  }

  .card p {
    font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
  }
}