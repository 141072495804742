.sobre-nosotros-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    text-align: justify;
    
  }
  
  .text-column {
    width: 60%;
    margin-right: 20px;
  }
  
  .text-column h1 {
    font-size: 2.3rem;
    margin-bottom: 20px;
    color: #23325A;
    font-weight: 900; 
  }
  
  .text-column h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #23325A;
    font-weight: 900;
  }
  
  .text-column p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    line-height: 1.6;
    color: #777;;
    border-radius: 10px; /* Aplicar border-radius */ 
  }

  .text-column strong {
    color: #25A882; /* Color verde */
    font-weight: 800;
    font-size: 1.2rem;
}
  
  .image-column {
    width: 30%;
  }
  
  .image-column img {
    width: 100%;
    margin: 40px auto 0 20px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }


  @media (max-width: 1000px) {
    .sobre-nosotros-container {
        flex-direction: column;
        align-items: center;
        
    }
  
    .image-column {
        width: 80%;
        margin-bottom: 20px;
    }
  
    .image-column img {
        width: 60%;
        height: auto;
        margin: 0 auto; /* Centrar la imagen */
        display: block; /* Asegurar que el margen automático funcione */
         }
  
    .text-column {
        width: 90%;
        margin-right: 0;
        text-align: left;
    }
  }


  @media (max-width: 480px) {
    .sobre-nosotros-container {
        padding: 20px 10px; /* Ajustar padding horizontal */
        margin: 0 10px; /* Ajustar margen horizontal */
    }

    .text-column {
        width: 100%;
        margin-right: 0;
        text-align: left;
    }

    
    .text-column h1 {
      margin-top: 5px; /* Reducir margen superior */
      margin-bottom: 10px; /* Reducir margen inferior */
    }

    .text-column h2 {
      margin-top: 5px; /* Reducir margen superior */
      margin-bottom: 5px; /* Reducir margen inferior */
    }

    .image-column {
        width: 100%;
        margin-bottom: 20px;
    }

    .image-column img {
        width: 90%;
        height: auto;
        margin: 0 auto; /* Centrar la imagen */
        display: block; /* Asegurar que el margen automático funcione */
    }
}

/* Media queries para pantallas grandes */
@media (min-width: 2000px) {
  .text-column h1 {
      font-size: 3.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column h2 {
      font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column p {
      font-size: 1.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column strong {
      font-size: 1.8rem; /* Ajustar el tamaño de la fuente */
  }
}

@media (min-width: 3000px) {
  .text-column h1 {
      font-size: 4.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column h2 {
      font-size: 3rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column p {
      font-size: 2rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column strong {
      font-size: 2.2rem; /* Ajustar el tamaño de la fuente */
  }
}

@media (min-width: 4000px) {
  .text-column h1 {
      font-size: 5.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column h2 {
      font-size: 3.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column p {
      font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
  }

  .text-column strong {
      font-size: 2.5rem; /* Ajustar el tamaño de la fuente */
  }
}